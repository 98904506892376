//@ts-nocheck is added because of the swiper module types has some errors, until the types are fixed, we will use ts-nocheck
'use client';
import { SliderLeft, SliderRight } from '@/src/features/ui/icons/icons';
import { ResponseSlider } from '@/src/interfaces/data-contracts';
import { DeviceContext } from '@/src/store/contexts/device-context';
import classNames from 'classnames';
import Image from 'next/image';
import React, { Fragment, useContext, useRef } from 'react';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import { InnerContainer } from '../../common/inner-container';
import { CustomSliderClickEvent, CustomSliderViewEvent, DataLayerService } from '@/lib/data-layer/data-layer';
import { RelLink } from '@/src/features/ui/rel-link';
import SegmentHub from '@/src/app/api/segmenthub/events';
import { useRouter } from 'next/navigation';

type Props = {
  data: ResponseSlider[];
};
const dataLayer = new DataLayerService();
const shownSliderItems = [];
export const FullWidthImgSlider: React.FC<Props> = ({ data }) => {
  const router = useRouter();
  const swiperRef = useRef<Swiper>();
  const { isMobile } = useContext(DeviceContext);
  const handleShownEvent = (index: 0) => {
    if (!data || data.length === 0) {
      return;
    }
    let item = data[index];
    if (shownSliderItems.includes(item.id)) {
      return;
    }
    shownSliderItems.push(item.id);
    setTimeout(() => {
      dataLayer.sendEvent(
        new CustomSliderViewEvent(
          ' Slider ',
          'Ana Sayfa',
          'Promotion Impression',
          item.id, // Ürünün id bilgisi gönderilmelidir.
          item.name, // Kampanyanın ismi olmalıdır.
          item.link, // BO'da tanımlanan link gönderilmelidir.
          item.displayOrder // BO Slider Sırası
        )
      );
    }, 1000);
  };
  const handleClickEvent = (item: {}) => {
    dataLayer.sendEvent(
      new CustomSliderClickEvent(
        ' Slider ',
        'Ana Sayfa',
        'Promotion Click',
        item.id, // Ürünün id bilgisi gönderilmelidir.
        item.name, // Kampanyanın ismi olmalıdır.
        item.link, // BO'da tanımlanan link gönderilmelidir.
        item.displayOrder // BO Slider Sırası
      )
    );
  };

  const handleClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: ResponseSlider) => {
    e.preventDefault();
    if (!item.link) return;
    await SegmentHub.purchaseNative(item.id);

    if (item.target === '_blank') window.open(item.link);
    else router.push(item.link);
  };
  return (
    <InnerContainer>
      <div className="relative w-full">
        <Swiper
          modules={[Pagination]}
          slidesPerView={1}
          loop
          pagination={{
            clickable: true,
            type: 'bullets',
            el: '.full-image-swiper-pagination',
          }}
          onBeforeInit={(swiper: any) => {
            swiperRef.current = swiper;
          }}
          onInit={(swiper) => handleShownEvent(swiper.realIndex)}
          onSlideChange={(swiper) => handleShownEvent(swiper.realIndex)}
        >
          {data.map((item, index) => (
            <Fragment key={item.imagePath}>
              {item.imagePath && (
                <SwiperSlide
                  key={item.imagePath}
                  className="md:w[1120px] h-[171px] w-[343px] md:h-[373px]"
                  onClick={() => handleClickEvent(item)}
                >
                  {item.link ? (
                    <RelLink
                      onClick={(e) => handleClick(e, item)}
                      href={item.link}
                      className="md:w[1120px] relative flex h-[434px] w-full items-center justify-center self-center overflow-hidden rounded-3xl md:h-[360px]"
                    >
                      <Image
                        quality={80}
                        src={item.imagePath}
                        key={item.imagePath}
                        alt={item.name ?? ''}
                        sizes={`${item.imagePath} 1200w,  ${item.imagePath}?w=200 200w, ${item.imagePath}?w=400 400w,  ${item.imagePath}?w=800 800w,  ${item.imagePath}?w=1024 1024w`}
                        decoding="async"
                        // loading={index === 0 ? 'lazy' : 'eager'}
                        loading="eager"
                        width={1120}
                        height={434}
                      />
                    </RelLink>
                  ) : (
                    <Image
                      quality={80}
                      src={item.imagePath}
                      key={item.imagePath}
                      alt={item.name ?? ''}
                      sizes={`${item.imagePath} 1200w,  ${item.imagePath}?w=200 200w, ${item.imagePath}?w=400 400w,  ${item.imagePath}?w=800 800w,  ${item.imagePath}?w=1024 1024w`}
                      decoding="async"
                      // loading={index === 0 ? 'lazy' : 'eager'}
                      loading="eager"
                      width={1120}
                      height={434}
                    />
                  )}
                </SwiperSlide>
              )}
            </Fragment>
          ))}
        </Swiper>
        <button
          className={classNames(
            isMobile ? 'hidden' : '',
            'swiper-button navPrevButton absolute -left-7 top-2/4 z-10 -mt-7 cursor-pointer'
          )}
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <SliderLeft height={56} width={56} />
        </button>
        <button
          className={classNames(
            isMobile ? 'hidden' : '',
            'swiper-button navNextButton absolute -right-7 top-2/4 z-10 -mt-7 cursor-pointer'
          )}
          onClick={() => swiperRef.current?.slideNext()}
        >
          <SliderRight height={56} width={56} className={classNames('')} />
        </button>
        <div className={'full-image-swiper-pagination mt-4 md:mt-6'}></div>
      </div>
    </InnerContainer>
  );
};
