'use client';
import { ResponseBank } from '@/src/interfaces/data-contracts';
import classNames from 'classnames';
import { FC, useState } from 'react';
import { InnerContainer } from '../../ui/common/inner-container';
import Heading from '../../ui/heading';
import ShowMoreBtn from '../../ui/show-more-btn/show-more-btn';
import { ListItem } from './list-item';

interface Props {
  contractedBanksData: ResponseBank[];
  parentPath?: string | null;
  title?: string;
  alignTitle?: 'text-center' | 'text-left';
}

const visibleBankCount = 18;

export const ContractedBanks: FC<Props> = (props) => {
  const incomingData = props.contractedBanksData;
  const incomingDataLength = incomingData?.length > visibleBankCount;
  const [contentShowStatus, setContentShowStatus] = useState(true);
  const onShowStateHandler = (incomingStatus: boolean | ((prevState: boolean) => boolean)) => {
    setContentShowStatus(incomingStatus);
  };

  const initialData = incomingData?.map((item) =>
    item.logoPath && item.name && item.seoUrl ? (
      <ListItem
        key={item.name}
        logoPath={item.logoPath}
        name={item.name}
        seoUrl={item.seoUrl}
        parentPath={props.parentPath && props.parentPath}
      />
    ) : (
      item.logoPath && item.name && <ListItem key={item.name} logoPath={item.logoPath} name={item.name} />
    )
  );

  return (
    <InnerContainer className="flex flex-col gap-5 md:gap-8">
      <Heading tagType="h3" cls={`${props.alignTitle || 'text-center'} text-black1000 text-lg md:text-2xl`} bolded>
        {props.title || `Anlaşmalı Bankalar ve Faktoringler İle En Avantajlı Teklifleri Al`}
      </Heading>
      {/*ul pr-[6px] due to borders occupying 1px each, 6 cols = 6px reserved for borders*/}

      {incomingDataLength ? (
        <ul
          className={classNames(
            'grid grid-cols-3 gap-4 truncate pr-[6px] md:grid-cols-6 md:gap-8',
            { ['h-[230px] md:h-[305px]']: contentShowStatus },
            { ['h-auto']: !contentShowStatus }
          )}
        >
          {initialData}
        </ul>
      ) : (
        <ul className={classNames('grid h-auto grid-cols-3 gap-4 truncate pr-[6px] md:grid-cols-6 md:gap-8')}>
          {initialData}
        </ul>
      )}

      {incomingDataLength && (
        <ShowMoreBtn showMoreText="Daha fazla gör" showLessText="Daha az gör" onShowState={onShowStateHandler} />
      )}
    </InnerContainer>
  );
};
